@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Raleway:wght@800&display=swap');

body {
  background: #FFCAB1;
  height: 100vh;
}

.container {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.card {
  margin: auto;
  padding: 1em;
  background: #F2F5DE;
  width: 30em;
  box-shadow: 10px 0 0px 0px #D0FFB7;
  color: #40362c;
  border-radius: 15px;
}

.header {
  font-family: 'Permanent Marker', cursive;
}

.linky {
  font-family: 'Raleway', sans-serif;
}

a:link {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:visited {
  color: #40362c;
}

.logoimg {
  margin: auto;
  border-radius: 50%;
  width: auto;
  height: 6em;
}